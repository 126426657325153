import { fetchList as fetchCity } from '@/api/city'

export default {
    data() {
        return {
            cityList: [],
            rawCityList: [],
        }
    },
    mounted() {
        fetchCity().then(res => {
            if (Array.isArray(res)) {
                this.rawCityList = Object.freeze(res)
                this.cityList = this.buildTree(Object.freeze(res), null)
            }
        })
    },
    methods: {
        buildTree(list, pid) {
            const tree = [];
            list.forEach((item) => {
                if (item.pid === pid) {
                    let children = this.buildTree(list, item.id);

                    let params = {
                        ...item,
                        key: item.id,
                    }

                    if (children.length > 0) {
                        params.children = children;
                    }

                    tree.push(params);
                }
            });

            return tree;
        }

    }
}