<template>
  <a-table bordered :dataSource="list" :pagination="false">
    <a-table-column title="专业">
      <template slot-scope="text, record, index">
        <span v-if="disabled">
          <DataDictFinder dictType="contract_scale" :dictValue="text.major" />
        </span>
        <a-select
          v-else
          v-model="text.major"
          style="width: 250px"
          @change="() => onChangeMajor(index)"
        >
          <a-select-option v-for="item in majorList" :key="item.value">{{
            item.name
          }}</a-select-option>
        </a-select>
      </template>
    </a-table-column>
    <a-table-column title="子专业">
      <template slot-scope="text, record, index">
        <span v-if="disabled">
          {{ getSubMajor(text.major, text.majorSub) }}
        </span>
        <a-select
          v-else
          v-model="text.majorSub"
          style="width: 250px"
          @change="() => onChangeMajorSub(index)"
        >
          <a-select-option
            v-for="item in getSubMajorList(text.major)"
            :key="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </template>
    </a-table-column>
    <a-table-column title="规模类型">
      <template slot-scope="text">
        <span v-if="disabled">
          {{ getScale(text.major, text.majorSub, text.scale) }}
        </span>
        <a-select v-else v-model="text.scale" style="width: 250px">
          <a-select-option
            v-for="item in getScaleList(text.major, text.majorSub)"
            :key="item.value"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </template>
    </a-table-column>
    <a-table-column title="操作" align="center" width="120px" v-if="!disabled">
      <template slot-scope="text, record, index">
        <a href="#" class="danger" @click.prevent="removeText(index)">删除</a>
      </template>
    </a-table-column>
  </a-table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    majorList() {
      return this.findDataDict("contract_scale");
    },
  },

  methods: {
    onChangeMajor(index) {
      const newList = [...this.list];
      newList.splice(index, 1, {
        ...newList[index],
        majorSub: "",
        scale: "",
      });
      this.$emit("setList", newList);
    },
    onChangeMajorSub(index) {
      const newList = [...this.list];
      newList.splice(index, 1, {
        ...newList[index],
        scale: "",
      });
      this.$emit("setList", newList);
    },
    getSubMajorList(major) {
      if (major) {
        const obj = this.majorList.find((item) => item.value === major);
        return obj && Array.isArray(obj.children) ? obj.children : [];
      } else {
        return [];
      }
    },
    getSubMajor(major, majorSub) {
      const obj = this.getSubMajorList(major).find(
        (item) => item.value === majorSub
      );
      return obj ? obj.name : "";
    },

    getScaleList(major, majorSub) {
      if (major && majorSub) {
        const subMajorList = this.getSubMajorList(major);
        const obj = subMajorList.find((item) => item.value === majorSub);
        return obj && Array.isArray(obj.children) ? obj.children : [];
      } else {
        return [];
      }
    },

    getScale(major, majorSub, scale) {
      const obj = this.getScaleList(major, majorSub).find(
        (item) => item.value === scale
      );
      return obj ? obj.name : "";
    },

    removeText(index) {
      this.$emit("remove", index);
    },
  },
};
</script>