<template>
  <div>
    <a-table bordered :dataSource="list" :pagination="false">
      <a-table-column title="收款进度编号" align="center">
        <template slot-scope="text, record, index">
          {{ index + 1 }}
        </template>
      </a-table-column>
      <a-table-column title="收款项名称">
        <template slot-scope="text">
          <span v-if="disabled">
            {{ text.name }}
          </span>
          <a-input v-else v-model="text.name" style="width: 400px" />
        </template>
      </a-table-column>
      <a-table-column title="收款比例(%)">
        <template slot-scope="text">
          <div>
            <span v-if="disabled">
              {{ text.ratio }}
            </span>
            <a-input-number
              v-else
              :max="100"
              v-model="text.ratio"
              style="width: 125px"
            />
          </div>
        </template>
      </a-table-column>
      <a-table-column title="收款金额(万元)" align="right">
        <template slot-scope="text">
          <span v-if="typeof text.ratio === 'number'">
            <Money :money="money * 10000 * text.ratio * 0.01" />
          </span>
        </template>
      </a-table-column>
      <a-table-column
        title="操作"
        align="center"
        width="120px"
        v-if="!disabled"
      >
        <template slot-scope="text, record, index">
          <a href="#" class="danger" @click.prevent="removeText(index)">删除</a>
        </template>
      </a-table-column>
    </a-table>
  </div>
</template>

<script>
import watermark from "@/mixins/watermark";
export default {
  mixins: [watermark],

  props: {
    money: {
      type: Number,
      default: 0,
    },

    list: {
      type: Array,
      default: () => [],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    removeText(index) {
      this.$emit("remove", index);
    },
  },
};
</script>