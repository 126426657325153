<template>
  <div>
    <a-button @click="propertyVisible = true" class="ellipsis" style="margin-top: 4px; text-align: left" block>
      {{ selectedProperty.name }}
    </a-button>
    <a-modal :visible="propertyVisible" title="选择合同性质" @cancel="propertyVisible = false" :footer="null">
      <div class="property">
        <div v-for="item in contractCategoryList" :key="item.value">
          <div class="property-name">{{ item.name }}</div>
          <div v-if="Array.isArray(item.children)">
            <div class="item" v-for="element in item.children" :key="element.value" @click="onClick(element)">
              {{ element.name }}
            </div>
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    selectedProperty: {
      type: Object,
    },
  },
  data() {
    return {
      propertyVisible: false,
    };
  },
  computed: {
    ...mapGetters("setting", ["findDataDict"]),
    contractCategoryList() {
      return this.findDataDict("contractCategory");
    },
  },
  methods: {
    onClick(element) {
      this.$emit("change", element);
      this.propertyVisible = false;
    },
  },
};
</script>

<style lang='less' scoped>
.property {
  .property-name {
    cursor: pointer;
    font-weight: bold;
    font-size: 13px;
    margin-bottom: 8px;
    color: #1890ff;
  }

  .item {
    cursor: pointer;
    margin-left: 2em;
    margin-bottom: 4px;
    color: #1890ff;
  }
}
</style>